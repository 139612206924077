/**
 *
 * prices that go for $0.001 or lower x 500 more expensive
 * prices that go for 0.03 or lower 100 x  more expensive
 * price that go $0.05 or lower x 50 more expensive
 * prices that go for 0.1 or lower 20x more expensive
 * prices that go for 0.20 or lower 10x more expensive
 * prices that go for 0.35 or lower 7 x more expensive
 * prices lower than 0.50 x and higher 5 x more expensive
 *
 */
export default function adjustPrice(price: number = 0) {
  if (price <= 0.001) {
    return price * 500;
  }

  if (price <= 0.03) {
    return price * 100;
  }

  if (price <= 0.05) {
    return price * 50;
  }

  if (price <= 0.1) {
    return price * 20;
  }

  if (price <= 0.2) {
    return price * 10;
  }

  if (price <= 0.35) {
    return price * 7;
  }

  return price * 5;
}
