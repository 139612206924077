import { PayPalButton } from "react-paypal-button-v2";
import CategoryInput from "./CategoryInput";
import { useState } from "react";
import classNames from "classnames";
import ServiceInput from "./ServiceInput";
import numbro from "numbro";
import css from "../../styles/order-widget.module.css";
import adjustPrice from "../../common/adjustPrice";

const OrderWidget = (props) => {
  const initialService = props.initialService;
  const [category, setCategory] = useState(initialService?.category ?? null);
  const [service, setService] = useState(initialService ?? null);
  const [quantity, setQuantity] = useState(null);
  const [link, setLink] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const price =
    quantity && service
      ? adjustPrice(
          Number((quantity || 0) / 1000 || 0) * Number(service?.rate || 0)
        )
      : 0;

  const invalidQuantity =
    (quantity && quantity < service?.min) ||
    (quantity && quantity > service?.max);

  if (error) {
    return (
      <div className="px-4 py-4 flex flex-col items-center justify-center text-gray-500">
        <div>
          <img
            width="140"
            src="/streamline-icon-credit-card-invalid@140x140.png"
          />
        </div>
        <p className="block text-center">
          Something went wrong while processing your order. Your payment method
          was not charged. If this keeps happening, contact us at:{" "}
          <a href="mailto:support@rantic.com">support@rantic.com</a>
        </p>
      </div>
    );
  }

  if (success) {
    return (
      <div className="px-4 py-4 flex flex-col items-center justify-center text-gray-500">
        <div>
          <img
            width="140"
            src="/streamline-icon-credit-card-valid@140x140.png"
          />
        </div>
        <p className="block text-center">
          Your order was successfully submitted
        </p>
      </div>
    );
  }

  return (
    <div className="px-4 py-5 sm:p-6">
      <div>
        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          Please provide information below to place an order.
        </p>
      </div>
      <div className="mt-6 sm:mt-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-6">
          <label
            htmlFor="category"
            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          >
            Category
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <CategoryInput
                id="category"
                value={category}
                initialValue={initialService?.category}
                onChange={(newCategory) => setCategory(newCategory)}
              />
            </div>
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-6">
          <label
            htmlFor="service"
            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          >
            Service
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <ServiceInput
                id="service"
                category={category}
                value={service?.id}
                initialValue={initialService?.id}
                onChange={(newService) => setService(newService)}
              />
            </div>
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-6">
          <label
            htmlFor="link"
            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          >
            Link
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                http://
              </span>
              <input
                id="link"
                className="flex-1 form-input block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 border border-gray-300"
                value={link}
                onChange={(event) => setLink(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-6">
          <label
            htmlFor="quantity"
            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          >
            Quantity
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="relative max-w-lg flex rounded-md shadow-sm">
              <input
                id="quantity"
                className={classNames(
                  "flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  {
                    "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red": invalidQuantity,
                  }
                )}
                disabled={!service}
                type="number"
                min={service?.min}
                max={service?.max}
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
              />
              {invalidQuantity ? (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-red-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
            {service && invalidQuantity ? (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Quantity must be a number between{" "}
                {numbro(service?.min).format({ thousandSeparated: true })} and{" "}
                {numbro(service?.max).format({ thousandSeparated: true })}
              </p>
            ) : null}
            {service && !invalidQuantity ? (
              <p className="mt-2 text-sm text-gray-500" id="email-description">
                A number between{" "}
                {numbro(service?.min).format({ thousandSeparated: true })} and{" "}
                {numbro(service?.max).format({ thousandSeparated: true })}
              </p>
            ) : null}
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mt-6">
          <label
            htmlFor="quantity"
            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          >
            Total Price
          </label>
          <div className="max-w-lg flex relative rounded-md shadow-sm border border-gray-300">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm sm:leading-5">$</span>
            </div>
            <input
              id="price"
              className="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
              placeholder="0.00"
              aria-describedby="price-currency"
              value={numbro(price).format({
                thousandSeparated: true,
                trimMantissa: true,
                mantissa: 2,
              })}
              disabled
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span
                className="text-gray-500 sm:text-sm sm:leading-5"
                id="price-currency"
              >
                USD
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("text-center pt-6 mt-6 sm:border-t", {
          [css.disabledButton]: invalidQuantity || !quantity || !link,
        })}
      >
        <PayPalButton
          options={{
            clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
          }}
          style={{
            color: "silver",
            label: "pay",
            height: 45,
          }}
          amount={numbro(price).format({
            thousandSeparated: false,
            trimMantissa: true,
            mantissa: 2,
          })}
          shippingPreference="NO_SHIPPING"
          onError={() => {
            setError(true);
          }}
          onSuccess={async (details, data) => {
            try {
              const response = await fetch("/api/orders/create", {
                method: "post",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  serviceId: service?.id,
                  link,
                  quantity,
                  name: `${details.payer.name.given_name} ${details.payer.name.surname}`,
                  email: details.payer.email_address,
                  price,
                  paymentDetails: details,
                }),
              });

              if (response.status === 200) {
                setSuccess(true);
              } else {
                setError(true);
              }
            } catch (e) {
              setError(true);
            }
          }}
        />
      </div>
    </div>
  );
};

export default OrderWidget;
