import useSWR from "swr";
import { useEffect, useState } from "react";
import { get, omit } from "lodash";

const CategoryInput = (props) => {
  const { onChange } = props;
  const { data, error } = useSWR("/api/services/categories");
  const [initial, setInitial] = useState(props.initialValue);
  const categories = data?.categories;

  useEffect(() => {
    if (initial && categories) {
      onChange(initial);
      setInitial(null);
    } else {
      onChange(get(categories, 0));
    }
  }, [categories]);

  return (
    <div className="inline-block relative w-full">
      <select
        disabled={!data}
        className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 pr-8 ease-in-out sm:text-sm sm:leading-5"
        {...omit(props, ["initialValue"])}
        onChange={(event) => onChange(event.target.value)}
      >
        {data ? (
          categories?.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </select>
    </div>
  );
};

export default CategoryInput;
