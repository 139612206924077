import { useEffect, useState } from "react";
import useSWR from "swr";
import { get, find, omit } from "lodash";

const ServiceInput = (props) => {
  const { onChange } = props;
  const { category } = props;
  const [initial, setInitial] = useState(props.initialValue);
  const { data, error } = useSWR(() =>
    category ? `/api/services?category=${encodeURIComponent(category)}` : null
  );
  const services = data?.services;

  useEffect(() => {
    if (initial && services) {
      onChange(find(services, { id: initial }));
      setInitial(null);
    } else {
      onChange(get(services, 0));
    }
  }, [services]);

  return (
    <div className="inline-block relative w-full">
      <select
        disabled={!data}
        className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 pr-8 ease-in-out sm:text-sm sm:leading-5"
        {...omit(props, ["category", "initialValue"])}
        onChange={(event) =>
          onChange(find(services, { id: event.target.value }))
        }
      >
        {data ? (
          services?.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </select>
    </div>
  );
};

export default ServiceInput;
